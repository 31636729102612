import React from 'react';
import TopHeader from '../components/TopHeader';
import Footer from '../components/Footer';

function TokenPage() {
    return (
        <div className='container'>
            <TopHeader />

            <section className='header inner'>
                <div className='header__container'>
                    <h2 className='header__welcome'>World Champion Apes</h2>
                    <h1 className='header__title'>TOKEN & NFT</h1>
                </div>

            </section>

            <section className='company' id="token">
                <div className='company__container'>
                    <div className='company__pic'>
                        <img className='token-pic' src='./../images/token.png' alt='Team pic' />
                    </div>

                    <div className='company__info'>
                        <h4 className='company__info--subtitle'>Discover $WCA</h4>
                        <p className='company__info--desc'>The company will take responsibility for purchasing the rights of up-and-coming players in youth categories. It will receive funding initially through the private and public sale of the WCA token.<br /><br />
												This acquired right will make it possible to enter into sponsorship contracts for players within a period previously defined by the above-mentioned contract.</p>
                        <a href='https://www.lbank.com/en-US/trade/wca_usdt' target='_blank' rel="noreferrer" ><button className='yellow-btn'>BUY ON LBANK</button></a>
                    </div>
                </div>
            </section>
            <section className='features right tokenb' id="nft"> 
                <div className='features__container'>
                    <div className='features__bg'></div>
                    <div className='features__info'>
                        <h4 className='features__info--subtitle'>WCAPES</h4>
                        <p className='features__info--desc'>A unique 3D NFT collection of 5,000 Human Apes, categorized by rarity level and generated with hundreds of elements.<br />
												Each Human Apes account grants you access to incredible things, like:</p>
                        <ul className='features__info--desc' style={{ marginLeft: '2rem' }}>
                            <li>Exclusive Access to WCA Club</li>
                            <li>Play in a dedicated App called WCA BET WIN</li>
                            <li>Each NFT gives you the possibility of receiving rewards derived from the company's image rights.</li>
                            <li>Access to exclusive events and parties with the presence of WCA talents and testimonials who will be joining the project.</li>
                        </ul>
                        <a href="https://opensea.io/collection/wcapes" target='_blank' rel="noreferrer"><button className='yellow-btn'>OPENSEA</button></a>
                    </div>
                </div>
                <img className='features__first-collection-pic' src='./../images/first-collection.png' alt='features pic' />
            </section>

            <section className='features dark tokenb'>
                <div className='features__container'>
                    <div className='features__bg'></div>

                    <div className='features__info'>
                        <h4 className='features__info--subtitle'>WCAPES MUNDIAL</h4>
                        <p className='features__info--desc'>This second collection derives from one of the most popular sports events in the world: the FIFA World Cup. Hence, the collection is named "Mundial". <br></br>The players' faces are crafted in porcelain, taking inspiration from one of the skyscrapers erected in honor of Mondrian Doha. However, the collection is more than just a decorative piece as it offers various useful functions for its future owners.</p>
														<a href="https://opensea.io/collection/wcapes-mundial" target='_blank' rel="noreferrer"><button className='yellow-btn'>OPENSEA</button></a>
                    </div>
                </div>
                <img className='features__mundial-pic' src='./../images/mundial-pic.png' alt='features token' />
            </section>

{
 //           <div className='schermata-pic-cont'>
 //               <img className='schermata-pic' src='./../images/schermata-pic.png' alt='features token' />
 //           </div>
}

							<section className='features right tokenc'>
                <div className='features__container'>
                    <div className='features__bg'></div>

                    <div className='features__info'>
                        <h4 className='features__info--subtitle'>WCAPES VIP</h4>
                        <p className='features__info--desc'>Discover and collect NFTs of the most influential figures in <br></br> the Italian and international football world.</p>
                        <a href="https://opensea.io/collection/wcapes-vip"><button className='yellow-btn'>OPENSEA</button></a>
                    </div>
                </div>
                <img className='features__first-collection-pic' src='./../images/HomeCard.png' alt='features pic' />
            </section>

            <Footer />
        </div>
    )
}

export default TokenPage;