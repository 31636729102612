import React from 'react';
import TopHeader from '../components/TopHeader';
import Footer from '../components/Footer';

function Giveaway2() {

	return (
		
		<div className='container'>
			<TopHeader />
			<section style={{
				display: 'block',
				marginTop: "100px",
			}}>
				<h1 style={{
					fontSize: "45px",
					padding: "60px 0",
					textAlign: "center"
				}}>Win <span style={{ color: "#eac44a" }}>$50K</span> Worth of WCA!</h1>
				<iframe src="https://gleam.io/oV9JQ/win-50k-worth-of-wca" frameBorder="0" style={{
					width: "100%",
					maxWidth: "1200px",
					marginRight: "auto",
					marginLeft: "auto",
					display: "block",
					height: "140vh",
					border: "0px",
					backgroundColor: "#000"
				}}></iframe>

				

				<p style={{
					width: "90%",
					maxWidth: "900px",
					margin: "auto",
					display: "block",
					fontSize: "24px",
					textTransform: "uppercase",
					fontWeight: "700",
					margin: "50px auto",
					color: "#eac44a"
				}}>
					WCA $50K Giveaway Is Live Now. Make Sure To Join And Share With Your Friends To Get A Chance To Win
				</p>

				<div style={{
						border: "4px solid rgb(234, 196, 74)",
						margin: "50px auto 10px",
						maxWidth: "900px",
						margin: "auto",
						display: "block",
						padding: "25px 0",
						width: "90%"
				}}>

					<p style={{
						width: "90%",
						maxWidth: "900px",
						margin: "0 auto 20px",
						display: "block",
						fontSize: "24px",
						textTransform: "uppercase",
						fontWeight: "700",
						color: "#fff",
						textAlign: "center"

					}}>
						3 WINNERS
					</p>

					<ul style={{
						maxWidth: "900px",
						margin: "auto",
						display: "block",
						fontSize: "20px",
						textTransform: "uppercase",
						fontWeight: "700",
						lineHeight: "28px",
						textDecoration: "none",
						listStyle: "none",
						textAlign: "center"

					}}>
						<li style={{textDecoration: "none"}}>1° 25.000 $WCA</li>
						<li style={{textDecoration: "none"}}>2° 15.000 $WCA</li>
						<li style={{textDecoration: "none"}}>3° 10.000 $WCA​</li>
					</ul>

				</div>
				<p style={{
					width: "90%",
					maxWidth: "900px",
					margin: "auto",
					display: "block",
					fontSize: "18px",
					textTransform: "uppercase",
					fontWeight: "700",
					margin: "40px auto 20px"
				}}>
					Take part in our unmissable giveaway and you could win one of our three amazing prizes! The first prize is 25,000 dollars in WCA tokens, the second prize is 15,000 dollars in WCA tokens, and the third prize is 10,000 dollars in WCA tokens.
				</p>
				<p style={{
					width: "90%",
					maxWidth: "900px",
					margin: "auto",
					display: "block",
					fontSize: "18px",
					textTransform: "uppercase",
					fontWeight: "700",
					margin: "40px auto 20px"
				}}>
					This is a unique opportunity for our loyal supporters to gain a significant advantage. Don't miss out on this chance, participate now and test your luck!
				</p>

				<div style={{
						//border: "4px solid rgb(234, 196, 74)",
						margin: "50px auto 10px",
						maxWidth: "900px",
						margin: "auto",
						display: "block",
						padding: "25px 0",
						width: "90%"
				}}>

					<ul style={{
						maxWidth: "900px",
						margin: "auto",
						display: "block",
						fontSize: "18px",
						textTransform: "uppercase",
						fontWeight: "700",
						lineHeight: "30px",
						textDecoration: "none",
						listStyle: "none",
						color: "#eac44a",
						width: "90%",
						marginLeft: "0px"

					}}>
						<li style={{textDecoration: "none"}}>1. Follow @wcapes_official on Twitter = 1 Point</li>
						<li style={{textDecoration: "none"}}>2. Visit @worldchampionsapes on Instagram = 1 Point</li>
						<li style={{textDecoration: "none"}}>3. Join @wcapes_official on Telegram = 1 Point</li>
						<li style={{textDecoration: "none"}}>4. Retweet @wcapes_official on Twitter = 3 Point</li>
						<li style={{textDecoration: "none"}}>5. Tweet on Twitter = 3 Point</li>
						<li style={{textDecoration: "none"}}>6. Refer Friends For Extra Entries = 5 Point</li>

						
					</ul>

				</div>
			</section>
			<Footer />
		</div>
	)
}

export default Giveaway2;