import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import DAOPage from './pages/DAOPage';
import TokenPage from './pages/TokenPage';
import AssetsPage from './pages/AssetsPage';
import './css/main.css';
import Win100kwca from './pages/Win-100k-wca';
import Giveaway2 from './pages/Giveaway2';

function App() {
  return (
    // <HomePage />
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<HomePage />} />
        {/* <Route path='/company' element={<CompanyPage />} /> */}
        <Route path='/app' element={<DAOPage />} />
        <Route path='/token' element={<TokenPage />} />
        <Route path='/assets' element={<AssetsPage />} />
        <Route path='/win-100k-wca' element={<Win100kwca />} />
        <Route path='/giveaway' element={<Giveaway2 />} />
        <Route path='*' element={<HomePage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
