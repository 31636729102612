import React from "react";
import TopHeader from "../components/TopHeader";
import Footer from "../components/Footer";

function HomePage() {
	const scrollContentNavRight = () => {
		let deviceWidth = window.innerWidth;
		const teamList = document.querySelector(".team__list");
		const teamItem = document.querySelector(".team__list--item:first-child");
		if (deviceWidth <= 600) {
			teamList.scrollLeft += teamItem.offsetWidth;
		} else {
			teamList.scrollLeft += parseInt(teamItem.offsetWidth) + 21;
		}
	};
	const scrollContentNavLeft = () => {
		let deviceWidth = window.innerWidth;
		const teamList = document.querySelector(".team__list");
		const teamItem = document.querySelector(".team__list--item:first-child");
		if (deviceWidth <= 600) {
			teamList.scrollLeft -= teamItem.offsetWidth;
		} else {
			teamList.scrollLeft -= parseInt(teamItem.offsetWidth) + 21;
		}
	};

	const scrollRoadmapNavRight = () => {
		const teamList = document.querySelector(".roadmap__list");
		const teamItem = document.querySelector(".roadmap__list--item:first-child");
		teamList.scrollLeft += teamItem.offsetWidth;
	};

	const scrollRoadmapNavLeft = () => {
		console.log("click");
		const teamList = document.querySelector(".roadmap__list");
		const teamItem = document.querySelector(".roadmap__list--item:first-child");

		teamList.scrollLeft -= teamItem.offsetWidth;
	};

	return (
		<div className="container">
			<TopHeader />

			<section className="header">
				<div className="header__container">
					<h2 className="header__welcome">Welcome to</h2>
					<h1 className="header__title">World Champion Apes</h1>
					<p className="header__info">
						We are a scout agency with expert observers who will find the best prospects within the football industry.
						<br />
						Our use of blockchain technology allows us to make football accessible to everyone.
					</p>
					<a href="https://app.wcapes.com" target="_blank" rel="noreferrer">
						<button className="yellow-btn">JOIN THE DAO</button>
					</a>
				</div>

				<img className="header__pic" src="./../images/header-pic.png" alt="header pic" />
				<div className="header__menu">
					<button className="header__icon-btn">
						<a target="_blank" href="https://www.instagram.com/worldchampionsapes/" rel="noreferrer">
							<img src="./../icons/instagram-ico.png" alt="menu" />
						</a>
					</button>

					<button className="header__icon-btn">
						<a target="_blank" href="https://twitter.com/Wcapes_official" rel="noreferrer">
							<img src="./../icons/twitter-ico.png" alt="menu" />
						</a>
					</button>

					<button className="header__icon-btn">
						<a target="_blank" href="https://t.me/wcapes_official" rel="noreferrer">
							<img src="./../icons/send-ico.png" alt="menu" />
						</a>
					</button>

					<button className="header__icon-btn">
						<a target="_blank" href="https://www.linkedin.com/company/wcapes/" rel="noreferrer">
							<img src="./../icons/linkedin-ico.svg" alt="menu" />
						</a>
					</button>

					<button className="header__icon-btn">
						<a target="_blank" href="https://discord.com/invite/uRMMdaNKFv" rel="noreferrer">
							<img src="./../icons/controller-ico.png" alt="menu" />
						</a>
					</button>

					<button className="header__icon-btn">
						<a target="_blank" href="https://www.youtube.com/channel/UCnR1xMO5Kly0aeVihT5T7Og" rel="noreferrer">
							<img src="./../icons/youtube-ico.png" alt="menu" />
						</a>
					</button>
				</div>
			</section>

			<section className="about">
				<div className="about__container">
					<img className="about__team-pic" src="./../images/HomeCard.png" alt="Team pic" />

					<div className="about__info">
						<h4 className="about__info--subtitle">Company</h4>
						<p className="about__info--desc">
							The WCA token nourishes the corporate ecosystem by allowing its holders to partake in the organization's DAO platform.
							<br></br>
							<br></br>
							Holding WCA also means having the possibility, throw-out company growth, to make a profit from the value of it’s token. 
						</p>
						{/* <a href="https://highperform.tech/" className="yellow-btn" target="_blank" rel="noreferrer">
							LEARN MORE
						</a> */}
					</div>
				</div>
			</section>

			{/* <section className="features">
				<div className="features__container">
					<div className="features__bg"></div>

					<div className="features__info">
						<h4 className="features__info--subtitle">WCA StrongBox</h4>
						<p className="features__info--desc">Buy WCA StrongBoxes protect your $WCA from price volatility, and get access to the WCA Referral Program.</p>
						<a href="https://strongbox.wcapes.com" className="yellow-btn" target="_blank" rel="noreferrer">
							BUY NOW
						</a>
					</div>
				</div>
				<img className="features__token-pic" src="./../images/treasury.png" alt="WCA StrongBox" />
			</section> */}

			<section className="features right">
				<div className="features__container">
					<div className="features__bg"></div>

					<div className="features__info">
						<h4 className="features__info--subtitle">DAO</h4>
						<p className="features__info--desc">A platform that empowers the community, enabling individuals to vote and express their opinions on the company's proposals, attend meetings, and earn income from their assets. These are just a few of the opportunities available through in our platform. Log in and get started with the World Champion Apes world.</p>
						<a href="/app" className="yellow-btn">
							LEARN MORE
						</a>
					</div>
				</div>
				<img className="features__dao-pic" src="./../images/mac.png" alt="features pic" />
			</section>

			<section className="features">
				<div className="features__container">
					<div className="features__bg"></div>

					<div className="features__info">
						<h4 className="features__info--subtitle">Token & NFT</h4>
						<p className="features__info--desc">Up until today, participating in the world of football was a mere privilege granted only for a select handful, but now you have a genuine chance to be a part of it. Discover how to obtain the assets (Tokens and NFTs) of the World Champion Apes project today.</p>
						<a href="/token" className="yellow-btn">
							LEARN MORE
						</a>
					</div>
				</div>
				<img className="features__token-pic1" src="./../images/HomeCard2.png" alt="features token" />
			</section>



			<section className="features right">
				<div className="features__container">
					<div className="features__bg"></div>

					<div className="features__info">
						<h4 className="features__info--subtitle">Official Store</h4>
						<p className="features__info--desc">The official store that brings you into the amazing world of NFT with an exclusive range of clothing and gadgets from the WCAPES brand. We are here to satisfy your passion for crypto art, offering you the chance to express your unique style through high quality products.</p>
						<a href="https://shop.wcapes.com" className="yellow-btn" target="_blank" rel="noreferrer">
							BUY NOW
						</a>
					</div>
				</div>
				<img className="features__token-pic2" src="./../images/wcapes_hat.png" alt="WCAPES Hat" />
			</section>

			{/* SEZIONE ROADMAP */}
			<section className="roadmap">
				<div className="roadmap__container">
					<h3 className="roadmap__title">Roadmap</h3>

					<div className="roadmap__list">
						<div className="roadmap__list--item">
							<img className="roadmap-pic" src="./../images/roadmap3.png" alt="" />
						</div>
					</div>

					<div className="roadmap__nav">
						<button className="roadmap__nav--btn" onClick={scrollRoadmapNavLeft}>
							<img src="./../icons/left-btn.svg" alt="" />
						</button>

						<button className="roadmap__nav--btn" onClick={scrollRoadmapNavRight}>
							<img src="./../icons/right-btn.svg" alt="" />
						</button>
					</div>
				</div>
			</section>

			<section className="team">
				<div className="team__container">
					<h3 className="team__title">Ambassador</h3>

					<div className="team__list">
						<div className="team__list--item">
							<img className="team-pic" src="./../images/team/carlos.jpeg" alt="" />
							<h4 className="name">ROBERTO CARLOS</h4>
							<h5 className="position">Ambassador & Football Legend</h5>
							<p className="desc">Roberto Carlos da Silva, commonly known as Roberto Carlos, is a retired Brazilian footballer, widely regarded as one of the best left-backs in the history of the sport.</p>
						</div>

						<div className="team__list--item">
							<img className="team-pic" src="./../images/team/Zenga-cerchio-2.png" alt="" />
							<h4 className="name">WALTER ZENGA</h4>
							<h5 className="position">Ambassador & Football Legend</h5>
							<p className="desc">Walter Zenga is an Italian football manager and former player who most recently managed the Serie A club Cagliari. He also served as a goalkeeper for Inter Milan and the Italian national team for a significant period of time.</p>
						</div>

						<div className="team__list--item">
							<img className="team-pic" src="./../images/team/naif.jpeg" alt="" />
							<h4 className="name">NAIF HAZAZI</h4>
							<h5 className="position">Ambassador & Football Legend</h5>
							<p className="desc">Naif Hazazi is a Saudi Arabian retired footballer who played as a striker and is regarded as one of the best Saudi Arabian footballers of his generation.</p>
						</div>

						<div className="team__list--item">
							<img className="team-pic" src="./../images/team/frey.png" alt="" />
							<h4 className="name">SÉBASTIEN FREY</h4>
							<h5 className="position">Ambassador & Football Legend</h5>
							<p className="desc">Sébastien Frey is fifth among the top foreign players in Serie A and has enjoyed excellent consideration throughout his career, to the point of being considered among the best goalkeepers in Serie A in the 2000s.</p>
						</div>

						<div className="team__list--item">
							<img className="team-pic" src="./../images/team/girelli.jpeg" alt="" />
							<h4 className="name">CRISTIANA GIRELLI</h4>
							<h5 className="position">Ambassador & Football Legend</h5>
							<p className="desc">Cristiana Girelli is an Italian footballer who plays as a forward for Juventus and the Italy women's national team. She is considered one of the top female footballers in Italy.</p>
						</div>
					</div>

					<div className="team__nav">
						<button className="team__nav--btn" onClick={scrollContentNavLeft}>
							<img src="./../icons/left-btn.svg" alt="" />
						</button>

						<button className="team__nav--btn" onClick={scrollContentNavRight}>
							<img src="./../icons/right-btn.svg" alt="" />
						</button>
					</div>
				</div>
			</section>

			<Footer />
		</div>
	);
}

export default HomePage;
