import React from 'react';

export default function Footer() {
    return (
        <footer className='main-footer'>
            <a href='#top' className='topButton'></a>
            <div className='main-footer__container'>
                <a style={{
                    display: "block",
                    margin: "auto"
                }} href='../'><img className='main-footer__logo' src='./../images/header-logo.png' alt='Top logo' />
                </a>
                <div className='main-footer__links'>
                    <div className='main-footer__links--item'>
                        <h4 className='title'>Company Information</h4>
                        <p className='detail'>World Cup Apes AG <br></br>Lauriedstrasse 1, 6300 Zug (SUI)<br></br> Paid-up Share Capital 100.000 CHF</p> 
                        <p className='check-item'>FINMA</p>
                        <p className='check-item'>LBANK CONTROL</p>
                    </div>

                    <div className='main-footer__links--item'>
                        <h4 className='title'>Contact Us</h4>
                        <a className='email' href='mailto:info@wcapes.com'>info@wcapes.com</a>
                    </div>
                </div>

                <div className='main-footer__social'>
                    <a href='https://www.instagram.com/worldchampionsapes/' target={'_blank'} className='main-footer__social__icon-btn' rel="noreferrer">
                        <img src='./../icons/instagram-ico.png' alt='menu' />
                    </a>

                    <a href='https://twitter.com/Wcapes_official' target={'_blank'} className='main-footer__social__icon-btn' rel="noreferrer">
                        <img src='./../icons/twitter-ico.png' alt='menu' />
                    </a>

                    <a href='https://t.me/wcapes_official' target={'_blank'} className='main-footer__social__icon-btn' rel="noreferrer">
                        <img src='./../icons/send-ico.png' alt='menu' />
                    </a>

                    <a href='https://www.linkedin.com/company/wcapes/' target={'_blank'} className='main-footer__social__icon-btn' rel="noreferrer">
                        <img src='./../icons/linkedin-ico.svg' alt='menu' />
                    </a>

                    <a href='https://discord.com/invite/uRMMdaNKFv' target={'_blank'} className='main-footer__social__icon-btn' rel="noreferrer">
                        <img src='./../icons/controller-ico.png' alt='menu' />
                    </a>

                    <a href='https://www.youtube.com/channel/UCnR1xMO5Kly0aeVihT5T7Og' target={'_blank'} className='main-footer__social__icon-btn' rel="noreferrer">
                        <img src='./../icons/youtube-ico.png' alt='menu' />
                    </a>
                </div>
            </div>
        </footer>
    )
}