import React from 'react';
import TopHeader from '../components/TopHeader';
import Footer from '../components/Footer';

function AssetsPage() {
    return (
        <div className='container'>
            <TopHeader />

            <section className='header inner'>
                <div className='header__container'>
                    <h2 className='header__welcome'>World Champion Apes</h2>
                    <h1 className='header__title'>ASSETS</h1>
                </div>
            </section>

            <section className='company' id="token">
                <div className='company__container'>
                    <div className='company__pic'>
                        <img className='token-pic' src='./../images/assetsimage.png' alt='Team pic' />
                    </div>
                    <div className='company__info'>
                        <h4 className='company__info--subtitle'>NAB (Network on Blockchain) WCA</h4>
                        <p className='company__info--desc'>
												Most crypto projects rely on large networks that offer marketing and sales incentives and prize plans, managed by dedicated software. This software can manage a complex system of earnings at various levels for all individuals involved in sales. Herbalife is a typical example of network marketing. However, the WCA IT department has developed its own software and reward scheme designed to reach large communities that are not organized in this way. This product is unique because it is the first completely decentralized network software, created specifically for us but marketable throughout the entire Blockchain galaxy.												</p>
                    </div>
                </div>
            </section>
            <section className='features right tokenb' id="nft"> 
                <div className='features__container'>
                    <div className='features__bg'></div>
                    <div className='features__info'>
                        <h4 className='features__info--subtitle'>SWAP TRADE WCA</h4>
                        <p className='features__info--desc'>Swappy Trade WCA is a decentralized wallet that enables you to exchange and purchase digital coins. The unique feature of this product is the ability to swap coins between various blockchains without having to remove them from your wallet. This offers a high level of security, as well as an all-in-one solution for your needs. While it was designed specifically for WCA, it can also be marketed to other exchanges and operators in the sector.</p>
                    </div>
                </div>
                <img className='features__first-collection-pic' src='./../images/swap.png' style={{height: "auto"}} alt='features pic' />
            </section>

            <Footer />
        </div>
    )
}

export default AssetsPage;