import TopHeader from '../components/TopHeader';
import Footer from '../components/Footer';
import React, { useEffect, useState } from "react";

function DAOPage() {
	const [showVideo, setShowVideo] = useState(false);
	const [showVideo1, setShowVideo1] = useState(false);

    return (
        <div className='container'>
            <TopHeader />

            <section className='header inner'>
                <div className='header__container'>
                    <h2 className='header__welcome'>World Champion Apes</h2>
                    <h1 className='header__title'>Discover <br></br>World Champion Apes DAO</h1>
										<a href="https://app.wcapes.com" target={'_blank'}><button className='yellow-btn'>JOIN THE DAO</button></a>

                </div>
            </section>

            {
                showVideo ?
                    <div className='video-wrapper'>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/M1PvkgqvKqk" title="" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                    :
                    <section className='video'>
                        <div className='video__content'>
                            <h3 className='video__title'>Discover DAO</h3>
                            <p className='video__desc'>Discover the full potential of DAO WCA</p>
                            <button className='video__btn' onClick={() => { 
															setShowVideo(true) 
														}}>
                                <img src='./../icons/play-ico.svg' alt='play icon' />
                            </button>
                        </div>
                        <img className='video__bg' src='./../images/discover-dao.png' alt='company meetings video' />
                    </section>
            }

            <section className='features right'>
                <div className='features__container'>
                    <div className='features__bg'></div>

                    <div className='features__info'>
                        <h4 className='features__info--subtitle'>Your Assets</h4>
                        <p className='features__info--desc'>Monitor your NFT and token assets on the relevant pages. The following pages are available on our platform:</p>
                        <ul className='features__info--desc' style={{ marginLeft: '2rem' }}>
                            <li>Page to view your own NFTs from the first collection</li>
                            <li>Page to view your own NFTs from the Mundial collection (with the option to put NFT Mundial in Stake)</li>
                            <li>Page to view your own NFTs Vip</li>
                            <li>Page to view one's Tokens and how they were generated </li>
                        </ul>

                    </div>
                </div>
                <img className='features__your-assets-pic' src='./../images/mac.png' alt='features pic' />
            </section>

            <section className='features dark'>
                <div className='features__container'>
                    <div className='features__bg'></div>

                    <div className='features__info'>
                        <h4 className='features__info--subtitle'>Live Streaming</h4>
                        <ul className='features__info--desc' style={{ marginLeft: '2rem' }}>
                            <li>Schedule directories: Monitor the days and times of upcoming directories.</li>
                            <li>Streaming: Participate in private live streaming through dao.</li>
                            <li>Notifications: Receive email notifications to alert you about live</li>
                        </ul>
                    </div>
                </div>
                <img className='features__live-streaming-pic' src='./../images/mac2.png' alt='features token' />
            </section>

            <section className='features right'>
                <div className='features__container'>
                    <div className='features__bg'></div>

                    <div className='features__info'>
                        <h4 className='features__info--subtitle'>Voting</h4>
                        <p className='features__info--desc'>We enable the community to vote on crucial societal decisions. This is the essence of our DAO.</p>
                        <p className='features__info--desc'>Votes cannot be tampered with as every action is tracked and monitorable on the blockchain</p>
                    </div>
                </div>
                <img className='features__voting-pic' src='./../images/voting.png' alt='features pic' />
            </section>

            {
                showVideo1 ?
                    <div className='video-wrapper'>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/kbEpkhELvUw" title="" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                    :

            <section className='check-ranking'>
                        <div className='video__content'>
												<h4 className='check-ranking__title'>Check Ranking</h4>
												<p className='check-ranking__desc'>More prestige for your investments</p>
												<img className='check-ranking__pic' src='./../images/ranking.png' alt='features pic' />
												<button className='check-ranking__btn' onClick={() => { 
															setShowVideo1(true) 
														}}>
														<img src='./../icons/play-ico.svg' alt='play icon' />
												</button>
                        </div>
                    </section>
            }




            <Footer />
        </div>
    )
}

export default DAOPage;